@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

*,
body {
  font-family: "Outfit" !important;
  padding: 0;
  margin: 0;
}


h2 {
  font-weight: 600;
}





/* Footer.css */
.footer {
  background-color: #141A24;
  color: #b1b1b1;
  padding: 40px 20px;
}

.footer-top {
  padding-bottom: 20px;
  border-bottom: 1px solid #444;
}

.footer-tabs-social {
  display: flex;
  justify-content: left;
  align-items: center;
}

.footer-links {
  display: flex;
  gap: 5%;
  flex-direction: row;
  justify-content: left;
  margin-top: 20px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section li {
  margin-bottom: 10px;
}

.footer-social {
  text-align: center;
  margin-top: 2%;
}

.footer-social h4 {
  font-size: 1.2em;
  margin-bottom: 15px;
  color: #ffffff;
  text-transform: uppercase;
}

.social-icons {
  display: flex;
  gap: 20px;
}

.social-icons a {
  color: #b1b1b1;
  font-size: 1.5em;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #ffffff;
}

.footer-bottom {
  margin-top: 30px;
  text-align: center;
}

.footer-links-bottom ul {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  padding: 0;
  margin-bottom: 10px;
}

.footer-links-bottom li a {
  text-decoration: none;
  color: #ffffff;
  font-size: 1rem;
  transition: color 0.3s;
}

.footer-links-bottom li a:hover {
  color: #ffffff;
}

.footer-bottom p {
  margin: 0;
  color: #777;
  font-size: 0.9em;
}

/* Responsive Design */
@media (max-width: 768px) {

  .footer-div {
    width: 100%;
  }

  .footer {
    padding: 40px 5px;
  }

  .footer-tabs-social {
    flex-direction: column;
    align-items: center;
  }

  .footer-bottom {
    flex-direction: column;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
  }

  .footer-links-bottom ul {
    flex-direction: row;
    gap: 15px;
    margin-top: 7%;
  }

  .footer-links {
    display: flex;
    align-items: start;
    padding-left: 3%;
  }

}


.footer-div {
  width: 80%;
  margin: auto;
  padding: 2%;
  border: 1px solid #252E3D;
}









/* PropertyEnquiryForm.css */

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.empathy-section {
  flex: 1 1 300px;
  display: flex;
  justify-self: center;
  align-items: center;
}

.empathy-div {
  width: 300px;
  margin: 15% auto 0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.empathy-section h2 {
  color: #0056b3;
}

.empathy-section .colorful-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: linear-gradient(135deg, #00f, #ff0, #f0f);
  margin-top: 20px;
}

.form-section {
  flex: 1 1 300px;
  padding: 5%;
  background-color: #0D5BA9;
  color: white;
}

.form-section h3 {
  text-transform: uppercase;
  font-weight: 600;
}

.form-section h2 {
  margin: 10px 0;
}

.form-section form {
  display: flex;
  flex-direction: column;
}

.form-section input,
.form-section select,
.form-section textarea {
  padding: 20px 10px;
  margin-bottom: 15px;
  border: none;
  border-radius: 5px;
}

.phone-email {
  display: flex;
  gap: 10px;
}

.phone-email input {
  flex: 1;
}

.form-section .recaptcha {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.form-section button {
  padding: 15px 35px;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  font-weight: 600;
  width: fit-content;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-section button:hover {
  background-color: #f1f1f1;
  color: #0056b3;
}

@media (max-width: 768px) {

  .empathy-section {
    padding: 0 3%;
  }

  .empathy-div {
    width: 100%;
    margin: 7% auto 3%;
  }

  .form-section {
    box-sizing: border-box;
    width: 100%;
    padding: 5% 11%;
  }

  .container {
    flex-direction: column;
  }

  .phone-email {
    flex-direction: column;
  }
}






.journey-container {
  text-align: center;
  padding: 5% 10%;
}

.journey-title {
  font-size: 1.1em;
  color: #003399;
  text-transform: uppercase;
}

.journey-stats {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.stat-item {
  flex: 1;
  min-width: 220px;
  max-width: 300px;
  padding: 20px;
}

.stat-item h3 {
  color: #0066cc;
  font-size: 2em;
  font-weight: 600;
  margin: 0;
}

.stat-item p {
  color: #333;
  font-size: 1.1em;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .journey-stats {
    flex-direction: column;
    align-items: center;
  }

  .stat-item {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .journey-title {
    font-size: 1.5em;
  }

  .stat-item h3 {
    font-size: 1.3em;
  }

  .stat-item p {
    font-size: 1em;
  }
}







/* CSS (SmartPhilosophy.css) */
.smart-philosophy {
  margin: 0 auto 5%;
  box-shadow: 0px 4px 10px 0px #DADADA;
  width: 80%;
  border-radius: 15px;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tab-button {
  padding: 1.3rem 1rem;
  border: none;
  color: #000;
  background-color: white;
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;
  flex: 1;
}

.tab-button.active {
  background-color: #0D5BA9;
  color: #fff;
  border-radius: 5px;
}

.tab-content {
  padding: 2rem;
  background-color: #E5F2FF;
  text-align: center;
}

.icon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .smart-philosophy {
    width: 93%;
    margin: 5% auto 10%;
  }


  .tab-button {
    padding: 0.5rem;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .tabs {
    flex-direction: column;
  }

  .tab-button {
    flex: none;
    width: 100%;
  }
}







/* Awards.css */

.awards-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0 5%;
}

.a-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.a-tab-button {
  padding: 17px 20px;
  margin: 0 10px;
  border: none;
  background-color: white;
  cursor: pointer;
  font-weight: 600;
  width: 300px;
  box-shadow: 0px 4px 10px 0px #DADADA;
  border-radius: 7px;
}

.a-tab-button.active {
  background-color: #005BA9;
  color: #fff;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.card {
  background-color: #fff;
  padding: 20px 20px 35px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.card img {
  margin-bottom: 15px;
}

.card h3 {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 10px;
}

.card p {
  font-size: 0.9em;
  color: #646464;
}

@media (max-width: 768px) {

  .awards-container {
    padding-top: 3%;
  }

  .a-tab-button {
    padding: 8px 16px;
    font-size: 0.9em;
  }

  .cards-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 480px) {
  .card {
    padding: 15px;
  }

  .card h3 {
    font-size: 1em;
  }

  .card p {
    font-size: 0.8em;
  }
}







.n-card-layout {
  display: grid;
  gap: 20px;
  padding: 20px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}

.n-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.n-card-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.n-card-content {
  padding: 16px;
  flex-grow: 1;
}

.n-card-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 12px;
}

.n-card-button {
  margin-top: auto;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  color: #005BA9;
  background-color: white;
  border: 1px solid #005BA9;
  border-radius: 4px;
  font-size: 14px;
  display: inline-block;
}

.n-card-button:hover {
  background-color: #0056b3;
  color: white;
}

.view-all-button {
  margin: 20px auto 0;
  padding: 12px 40px;
  background-color: white;
  color: #005BA9;
  width: fit-content;
  border: 1px solid #005BA9;
  border-radius: 4px;
  cursor: pointer;
}

.view-all-button:hover {
  background-color: #0056b3;
  color: white;
}

/* Responsive styling */
@media (max-width: 768px) {
  .card-layout {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }
}

@media (max-width: 480px) {
  .card-title {
    font-size: 1rem;
  }
}





/* EnquireNow.css */
.enquire-now {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translate(37%, -50%) rotate(-90deg);
  background-color: #0066cc;
  color: white;
  padding: 10px 20px;
  border-radius: 8px 8px 0 0;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.enquire-now:hover {
  background-color: #005bb5;
}

@media (max-width: 768px) {
  .enquire-now {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .enquire-now {
    padding: 6px 12px;
    font-size: 12px;
  }
}


.slick-next:before {
  content: '' !important;
}

.slick-prev:before {
  content: '' !important;
}

.slick-prev {
  left: -35px !important;
}








/* PropertyOverview.css */

.property-overview-container {
  display: flex;
  flex-wrap: wrap;
  padding: 3% 6%;
}

.sidebar {
  width: 100%;
  max-width: 250px;
  padding: 20px;
  box-shadow: 0px 4px 10px 0px #DADADA;
  border-radius: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 10px;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
}

.sidebar ul li.active {
  background-color: #0056b3;
  color: #fff;
}

.content {
  flex: 1;
  padding: 20px;
}

.m-tab-content {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.highlights {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.highlight-item {
  flex: 1 1 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.highlight-item span {
  font-size: 2rem;
  margin-right: 10px;
}

.download-brochure {
  background-color: #0056b3;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .sidebar {
    width: 90%;
    max-width: none;
  }

  .property-overview-container {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .content {
    padding: 10px;
  }
}









/* NortheastOpportunities.css */

.opportunities-container {
  text-align: left;
}

.opportunities-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.opportunities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.opportunity-card {
  border: 1px solid gainsboro;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.opportunity-icon {
  font-size: 40px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .opportunities-title {
    font-size: 20px;
  }

  .opportunity-card {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .opportunities-title {
    font-size: 18px;
  }

  .opportunity-card {
    padding: 10px;
  }
}











.high-life-experience {
  padding: 20px;
}

.header-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.header-content {
  max-width: 600px;
  text-align: center;
}

.header-image img {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
}

.amenities-section {
  margin-top: 40px;
}

.amenities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.amenity-item {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  font-weight: 600;
  border: 1px solid gainsboro;
}

@media (min-width: 768px) {
  .header-section {
    flex-direction: row;
    justify-content: space-between;
  }

  .header-content {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .amenities-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}










/* Specifications.css */
.spec-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  padding: 16px;
}

.spec-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  padding: 16px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.spec-icon {
  margin-bottom: 16px;
}

.spec-icon img {
  width: 40px;
  height: 40px;
}

.spec-content h3 {
  font-size: 1.25em;
  margin-bottom: 8px;
  text-align: left;
}

.spec-content p {
  font-size: 1em;
  text-align: left;
  color: #555555;
}

@media (max-width: 1024px) {
  .spec-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .spec-container {
    grid-template-columns: 1fr;
  }

  .spec-content h3 {
    font-size: 1.1em;
  }

  .spec-content p {
    font-size: 0.95em;
  }
}











/* FloorPlanViewer.css */

.floor-plan-viewer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.tabs-navigation {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding-bottom: 10px;
}

.tab-button {
  padding: 10px 20px;
  border: none;
  border-bottom: 2px solid transparent;
  background: none;
  cursor: pointer;
  font-size: 16px;
  color: #555;
  transition: color 0.3s, border-color 0.3s;
}

.tab-button:hover {
  color: #007bff;
  border-bottom-color: #007bff;
}

.tab-button.active {
  color: white;
}

.tab-content {
  margin-top: 20px;
}

.floor-plan {
  display: none;
}

.floor-plan.visible {
  display: block;
}

.floor-plan-image {
  width: 50%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.floor-plan-details {
  margin-top: 20px;
}

.detail-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.label {
  font-weight: 500;
  margin-right: 10px;
}

.value {
  color: #333;
}

.view-masterplan-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view-masterplan-button:hover {
  background-color: #0056b3;
}